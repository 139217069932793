$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .poll-ui-builder.modal {
  .poll-number {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
  }

  .column {
    display: inline-block;
    width: calc(50% - 10px);
  }

  .d-editor-preview {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid var(--primary-low);
  }
}
